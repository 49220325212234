import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Bucket } from './model';




@Injectable()
export abstract class BucketService {

    abstract getBuckets(): Observable<Bucket[]>;

    abstract saveBucket(bucket: Bucket);
    abstract deleteBucket(bucket: Bucket);
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { MaterialModule } from '../material.module';

import { UserComponent } from './user.component';
import { SettingsComponent } from './settings.component';


import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTwitter, faGoogle, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faSignOutAlt, faCog } from '@fortawesome/free-solid-svg-icons';

import { AppRoutingModule } from '../app-routing.module';
import { SettingsAccountComponent } from './settings-account.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsSocialAccountsComponent } from './settings-social-accounts.component';
import { SettingsSocialAvatarComponent } from './settings-social-avatar.component';
import { SettingsAvatarComponent } from './settings-avatar.component';
import { UploadFormComponent } from '../uploads/upload-form/upload-form.component';
import { ImageControlComponent } from '../image-control/image-control.component';

@NgModule({
  declarations: [
    UserComponent,
    SettingsComponent,
    SettingsAccountComponent,
    SettingsSocialAvatarComponent,
    SettingsSocialAccountsComponent,
    SettingsAvatarComponent,
    UploadFormComponent,

    ImageControlComponent,
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    FontAwesomeModule,

    SettingsRoutingModule,
  ],
  exports: [
    UserComponent,
    SettingsComponent,
    SettingsAccountComponent,
  ],
  providers: [
  ],
})
export class UserModule {
  constructor() {
    library.add(faTwitter, faGoogle, faFacebook,
      faSignOutAlt, faCog );
  }
}

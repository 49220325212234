import { Injectable } from '@angular/core';

import { User, AuthProvider, AuthService, LoginInfo, UserImage } from '../services/auth.service';

import { Observable } from 'rxjs';


import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { map } from 'rxjs/operators';

@Injectable()
export class FirebaseAuthService extends AuthService {

    currentUser: Observable<User>;

    private static toUserImage(userInfo: firebase.UserInfo): UserImage {
        return {
            source: this.toAuthProvider(userInfo),
            imgUrl: userInfo.photoURL,
        };
    }

    private static toAuthProvider(userInfo: firebase.UserInfo): AuthProvider {
    switch (userInfo.providerId) {
        case 'google.com': return AuthProvider.GOOGLE;
        case 'facebook.com': return AuthProvider.FACEBOOK;
        case 'twitter.com': return AuthProvider.TWITTER;
        case 'password': return AuthProvider.PASSWORD;
            }
            return null;
    }

    private static toProviderId(provider: AuthProvider): string {
        switch (provider) {
            case AuthProvider.GOOGLE: return 'google.com';
            case AuthProvider.FACEBOOK: return 'facebook.com';
            case AuthProvider.TWITTER: return 'twitter.com';
            case AuthProvider.PASSWORD: return 'password';
        }
        console.log('ERROR: unkown/unsupported provider!: ', provider);
    }

    constructor(private afAuth: AngularFireAuth) {
        super();
        this.currentUser = this.afAuth.user.pipe(map(this.convert));
    }

    convert(u: firebase.User): User {
        if (u === null) {
            return null;
        }
        const self = this;
        return {
            uid: u.uid,
            displayName: u.displayName,
            images: u.providerData
                .filter(p => p.providerId !== FirebaseAuthService.toProviderId(AuthProvider.PASSWORD))
                .map(p => FirebaseAuthService.toUserImage(p)),
            linkedProviders: u.providerData.map(p => FirebaseAuthService.toAuthProvider(p)),
        } as User;
    }


    getCurrentUser(): Observable<User> {
        return this.currentUser;
    }


    private toFirebaseProvider(provider: AuthProvider): auth.AuthProvider {
        switch (provider) {
            case AuthProvider.GOOGLE: return new auth.GoogleAuthProvider();
            case AuthProvider.FACEBOOK: return new auth.FacebookAuthProvider();
            case AuthProvider.TWITTER: return new auth.TwitterAuthProvider();
        }
        console.log('ERROR: unkown/unsupported provider!: ', provider);
    }


    login(provider: AuthProvider): Promise<LoginInfo> {
        const result = new Promise<LoginInfo>((resolve, reject) => {
            this.afAuth.auth.signInWithPopup(this.toFirebaseProvider(provider)).then(res => {
                const newUser = res.additionalUserInfo.isNewUser;
                console.log('success', res);
                resolve({
                    user: this.convert(res.user),
                    isNewUser: newUser,
                });
            }).catch(error => {
                console.log('ERROR during login', error);
                reject(error);
            });
        });
       return result;
    }

    logout() {
        this.afAuth.auth.signOut();
    }

    getPhotoURL(): string {
        return this.afAuth.auth.currentUser.photoURL;
    }

    updatePhotoURL(photoURL: string) {
        const oldDisplayName = this.afAuth.auth.currentUser.displayName;
        this.afAuth.auth.currentUser.updateProfile({
            displayName: oldDisplayName,
            photoURL: photoURL,
        });
    }

    link(provider: AuthProvider) {
        this.afAuth.auth.currentUser.linkWithPopup(this.toFirebaseProvider(provider)).then(result => {
            console.log('success', result);
            this.afAuth.auth.updateCurrentUser(result.user);
        }).catch(error => {
            console.log('ERROR during linking', error);
        });


    }

    unlink(provider: AuthProvider) {
        this.afAuth.auth.currentUser.unlink(FirebaseAuthService.toProviderId(provider)).then(result => {
            console.log('success', result);
            this.afAuth.auth.updateCurrentUser(result);
        }).catch(error => {
            console.log('ERROR during unlinking', error);
        });

    }
}

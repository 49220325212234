import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Upload } from './upload';
import * as firebase from 'firebase';
import { finalize } from 'rxjs/operators';
import { AuthService, User } from '../services';
import { Observable } from 'rxjs';

export class UploadedFile {
  $key: string;
  owner: string;
  url: string;
}

@Injectable()
export class UploadService {

  private uploadsCollection: AngularFirestoreCollection<UploadedFile>;

  private currentUser: Observable<User>;
  private currentUserUid: string;

  constructor(
    private authService: AuthService,
    private aFireStore: AngularFirestore,
    private aStorage: AngularFireStorage
  ) {
    this.uploadsCollection = aFireStore.collection('/uploads');
    this.currentUser = this.authService.getCurrentUser();
    this.currentUser.subscribe(c => {
      if (c) {
        this.currentUserUid = c.uid;
      } else {
        this.currentUserUid = null;
      }
    });
  }

  private basePath = '/uploads';

  pushUpload(upload: Upload) {

    if (!this.currentUserUid) {
      console.log('Unknown user!');
      return;
    }

    const uploadId = this.aFireStore.createId();
    upload.$key = uploadId;

    const path = `${this.basePath}/${upload.file.name}`;

    const ref = this.aStorage.ref(path);
    const task = this.aStorage.upload(path, upload.file);

    // observe percentage
    upload.progress = task.percentageChanges();

   // this.uploadsCollection.doc()


    // get notified when the download URL is available
    task.snapshotChanges().pipe(
      finalize(() => upload.url = ref.getDownloadURL())
    )
    .subscribe();

    task
    .then(done => {
      console.log('Upload finished', done);
      done.ref.getDownloadURL().then(r => {
        console.log('-->', r);
        this.uploadsCollection.add({
          $key: upload.$key,
          owner: this.currentUserUid,
          url: r,
        }).then(done1 => {
          console.log('saving finished', done1);
        }).catch(error => {
          console.log('Error during save', error);
        });
      });
    })
    .catch(error => {
      console.log('Error during upload', error);
    });
  }

}

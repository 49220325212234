import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

export interface User {
    uid: string;
    displayName: string;
    linkedProviders: [AuthProvider];
    images: [UserImage];
    photoURL: string;
}

export interface UserImage {
    source: AuthProvider;
    imgUrl: string;
}

export interface LoginInfo {
    user: User;
    isNewUser: boolean;
}

export enum AuthProvider {
    PASSWORD,
    GOOGLE,
    TWITTER,
    FACEBOOK
}

@Injectable()
export abstract class AuthService {

    abstract getCurrentUser(): Observable<User>;

    abstract getPhotoURL(): string;
    abstract updatePhotoURL(photoURL: string);

    abstract login(provider: AuthProvider): Promise<LoginInfo>;
    abstract logout();

    abstract link(provider: AuthProvider);
    abstract unlink(provider: AuthProvider);

    getLabel(provider: AuthProvider) {
        switch (provider) {
            case AuthProvider.GOOGLE: return 'Google';
            case AuthProvider.TWITTER: return 'Twitter';
            case AuthProvider.FACEBOOK: return 'Facebook';
        }
        return '';
    }
}

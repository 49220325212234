import { Injectable } from '@angular/core';
import { UploadService } from '../services/upload.service';
import { Upload } from '../services/model';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

import { v4 as uuid } from 'uuid';
import { finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DBUpload } from './model';


@Injectable()
export class FirebaseUploadService extends UploadService {

    private uploadsCollection: AngularFirestoreCollection<DBUpload>;

    constructor(
        private aFirestore: AngularFirestore,
        private aFireStorage: AngularFireStorage,
    ) {
        super();
        this.uploadsCollection = aFirestore.collection('uploads');
    }

    public pushUpload(data: Blob): Upload {

        const uploadId = uuid();

        const path = `/uploads/${uploadId}`;

        const db: DBUpload = {
            path: path,
            bucket: '',
            creator: '',
            ready: false,
        };

        const doc = this.uploadsCollection.doc(uploadId);
        console.log('writing doc');
        doc.set(db);

        console.log('starting upload');
        const task = this.aFireStorage.upload(path, data);

        const sReady: Subject<boolean> = Subject.create();
        const sData: Subject<Blob> = Subject.create();

        const result: Upload = {
            id: uploadId,
            path: path,
            data: sData,
            progress: task.percentageChanges(),
            ready: sReady,
        };
        task.then(done => {
            console.log('upload done.');
            sData.next(data);
            sReady.next(true);
            console.log('updating doc ready state!');
            doc.update({ready: true});
        });

        return result;
    }

}

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService, User, ProfileService } from '../services';

@Component({
  selector: 'app-settings-account',
  templateUrl: './settings-account.component.html',
  styleUrls: ['./settings-account.component.css']
})
export class SettingsAccountComponent implements OnInit {

  userId: Observable<string>;

  private user: User;

  constructor(
    private profileService: ProfileService,
    private authService: AuthService,
  ) {
    this.userId = profileService.userId;
    this.authService.getCurrentUser().subscribe(r => {
      console.log(r);
      this.user = r;
    });
   }

  ngOnInit() {
  }

  init() {
    console.log('init()');
      console.log('calling initializedProfile', this.user);
      this.profileService.initializeProfile(this.user);
  }
}

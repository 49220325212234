import { Component, OnInit } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import {
  AuthService,
  AuthProvider,
  User
} from '../services';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  providers: [],
})
export class UserComponent implements OnInit {

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    private authService: AuthService,
    private router: Router,
    private sanitizer: DomSanitizer,
    ) { }

  ngOnInit() {

    this.authService.getCurrentUser().subscribe(u => {
      if (u) {
        this.isLoading$.next(false);
      }
    });
  }

  get photoUrl() {
    return this.authService.getPhotoURL();
  }

  get photoUrlSafe() {
    const url = this.authService.getPhotoURL();
    const safe =  this.sanitizer.bypassSecurityTrustStyle(`url(${url});`);
    console.log('photoUrl', safe);
    return safe;
  }


  log(o) {
    // window.console.log(o);
  }

  openSettings() {
    this.router.navigateByUrl('/settings');
  }

  getCurrentUser(): Observable<User> {
    return this.authService.getCurrentUser();
  }

  login(provider: AuthProvider) {
    this.authService.login(provider).then(result => {
      if (result.isNewUser) {
        console.log('Got a new user; redirecting to user creation');
        this.router.navigate(['/createAccount']);
      }
    }).catch(error => {
      console.log('FAIL ', error);
    });
  }

  loginWithGoogle() {
    this.login(AuthProvider.GOOGLE);
  }
  loginWithTwitter() {
    this.login(AuthProvider.TWITTER);
  }
  loginWithFacebook() {
    this.login(AuthProvider.FACEBOOK);
  }

  linkWithGoogle() {
    this.authService.link(AuthProvider.GOOGLE);
  }
  linkWithTwitter() {
    this.authService.link(AuthProvider.GOOGLE);
  }
  linkWithFacebook() {
    this.authService.link(AuthProvider.GOOGLE);
  }

  unlinkGoogle() {
    this.authService.unlink(AuthProvider.GOOGLE);
  }
  unlinkTwitter() {
    this.authService.unlink(AuthProvider.GOOGLE);
  }
  unlinkFacebook() {
    this.authService.unlink(AuthProvider.GOOGLE);
  }

  logout() {
    this.authService.logout();
  }
}

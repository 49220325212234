import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';

import {
  MatSidenavModule,
  MatButtonModule,
  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatCardModule,
  MatStepperModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatTabsModule,
  MatExpansionModule,
  MatButtonToggleModule,
  MatRadioModule,
  MatSelectModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,

} from '@angular/material';

@NgModule({
  imports: [
    FormsModule,

    MatListModule,
    MatSidenavModule,
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatSelectModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    FormsModule,

    MatListModule,
    MatSidenavModule,
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatSelectModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
  ]
})
export class MaterialModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExperienceMarketplaceComponent } from './experience-marketplace.component';

@NgModule({
  declarations: [ExperienceMarketplaceComponent],
  imports: [
    CommonModule
  ]
})
export class ExperienceMarketplaceModule { }

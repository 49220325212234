import { Component, OnInit, SimpleChanges, OnChanges, Input } from '@angular/core';
import { map } from 'rxjs/operators';
import { from, Observable } from 'rxjs';

import { AuthService, User, AuthProvider } from '../services';

@Component({
  selector: 'app-settings-avatar',
  templateUrl: './settings-avatar.component.html',
  styleUrls: ['./settings-avatar.component.css']
})
export class SettingsAvatarComponent implements OnInit, OnChanges {

  currentUser$: Observable<User>;

  photoUrl_: string;

  selectedUrls_: string[];

  get selectedUrls() {
    if (!this.selectedUrls_) {
      this.selectedUrls_ = [
        this.photoUrl
      ];
    }
    return this.selectedUrls_;
  }
  set selectedUrls(value) {
    const all: string[] = value;
    if (all.length === 0) {
      this.selectedUrls_ = [];
      this.photoUrl = null;
    } else if (all.length === 1) {
      this.selectedUrls_ = value;
      this.photoUrl = value[0];
    } else {
      const filtered = all.filter(el => !this.selectedUrls_.includes(el));
      this.photoUrl = filtered[0];
      this.selectedUrls_ = filtered;
    }
  }

  get photoUrl() {
    return this.authService.getPhotoURL();
  }
  set photoUrl(value) {
    console.log('SETTER CALLED ', value);
    this.photoUrl_ = value;
    this.authService.updatePhotoURL(value);
  }

  constructor(
    private authService: AuthService
  ) {
    this.currentUser$ = authService.getCurrentUser();

  }

  getAuthProviderLabel(provider: AuthProvider): string {
    return this.authService.getLabel(provider);
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
  }

  onPhotoUrlChange() {
    console.log(arguments);
  }


}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';


import { AuthService, AuthProvider, User } from '../services';


class XX {
  name: String;
}


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  providers: [],
})
export class ProfileComponent implements OnInit {

  user$: Observable<XX>;

  currentUser$: Observable<User>;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,

  ) {
    this.currentUser$ = authService.getCurrentUser();
   }

  getUserById(id): XX[] {
    window.console.log(id);
    return [{
      name: id,
    }];
  }

  ngOnInit() {
    window.console.log(this.route);

    this.user$ =  this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.getUserById(params.get('id')))
    );
  }

  isGoogleLinked(user: User): boolean {
    return user.linkedProviders.findIndex(e => e === AuthProvider.GOOGLE) !== -1;
  }

  isFacebookLinked(user: User): boolean {
    return user.linkedProviders.findIndex(e => e === AuthProvider.FACEBOOK) !== -1;
  }

  isTwitterLinked(user: User): boolean {
    return user.linkedProviders.findIndex(e => e === AuthProvider.TWITTER) !== -1;
  }

  linkWithGoogle() {
    this.authService.link(AuthProvider.GOOGLE);
  }
  linkWithTwitter() {
    this.authService.link(AuthProvider.TWITTER);
  }
  linkWithFacebook() {
    this.authService.link(AuthProvider.FACEBOOK);
  }

  unlinkGoogle() {
    this.authService.unlink(AuthProvider.GOOGLE);
  }
  unlinkTwitter() {
    this.authService.unlink(AuthProvider.TWITTER);
  }
  unlinkFacebook() {
    this.authService.unlink(AuthProvider.FACEBOOK);
  }

}

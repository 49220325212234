import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { User, Image } from '.';

export interface Profile {
    userid: string;
}

@Injectable()
export abstract class ProfileService {

    public userId: Observable<string>;
    public email: Observable<string>;
    public portrait: Observable<Image>;

    abstract updateEmail(email: string);
    abstract checkUserId(userId: string);
    abstract updateUserId(userId: string);

    abstract initializeProfile(user: User);
    abstract deleteProfile(user: User);
}

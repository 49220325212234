import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { User, AuthService, AuthProvider } from '../services';

@Component({
  selector: 'app-settings-social-accounts',
  templateUrl: './settings-social-accounts.component.html',
  styleUrls: ['./settings-social-accounts.component.css']
})
export class SettingsSocialAccountsComponent implements OnInit {

  currentUser$: Observable<User>;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.currentUser$ = authService.getCurrentUser();
   }

  ngOnInit() {
    window.console.log(this.route);
  }

  isGoogleLinked(user: User): boolean {
    return user.linkedProviders.findIndex(e => e === AuthProvider.GOOGLE) !== -1;
  }

  isFacebookLinked(user: User): boolean {
    return user.linkedProviders.findIndex(e => e === AuthProvider.FACEBOOK) !== -1;
  }

  isTwitterLinked(user: User): boolean {
    return user.linkedProviders.findIndex(e => e === AuthProvider.TWITTER) !== -1;
  }

  linkWithGoogle() {
    this.authService.link(AuthProvider.GOOGLE);
  }
  linkWithTwitter() {
    this.authService.link(AuthProvider.TWITTER);
  }
  linkWithFacebook() {
    this.authService.link(AuthProvider.FACEBOOK);
  }

  unlinkGoogle() {
    this.authService.unlink(AuthProvider.GOOGLE);
  }
  unlinkTwitter() {
    this.authService.unlink(AuthProvider.TWITTER);
  }
  unlinkFacebook() {
    this.authService.unlink(AuthProvider.FACEBOOK);
  }
}

import { Component, OnInit, Input } from '@angular/core';

import { Bucket } from './model/bucket';

@Component({
    selector: 'lt-bucket',
    templateUrl: './bucket.component.html',
    styleUrls: [ './bucket.component.css' ]
})

export class BucketComponent implements OnInit {

    @Input() bucket: Bucket = {
        id: '1',
        name: "My very first bucket",
        category: "Life goal",
        description: '',
    }

    constructor() {

    }

    ngOnInit() {
        
    }
}
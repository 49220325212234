import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingsComponent } from './settings.component';
import { SettingsAccountComponent } from './settings-account.component';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';
import { SettingsSocialAccountsComponent } from './settings-social-accounts.component';
import { SettingsAvatarComponent } from './settings-avatar.component';


const settingsRoutes: Routes = [
    {
        path: 'settings',
        component: SettingsComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'account',
            },
            {
                path: 'account',
                component: SettingsAccountComponent,
            },
            {
                path: 'social-accounts',
                component: SettingsSocialAccountsComponent,
            },
            {
                path: 'avatar',
                component: SettingsAvatarComponent,
            },
            {
                path: '**',
                component: PageNotFoundComponent,
            }
        ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(settingsRoutes,
   // {enableTracing: true}
    )],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }

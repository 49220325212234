import { Component, OnInit } from '@angular/core';

import { Bucket } from './model/bucket';

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material';

@Component({
    selector: 'lt-bucket-wizard',
    templateUrl: './bucketwizard.component.html',
    styleUrls: [ './bucketwizard.component.css' ]
})

export class BucketWizardComponent implements OnInit {

    basicInfoGroup: FormGroup;
    individualFormGroup: FormGroup;

    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;

    bucket: Bucket = {
        id: '1',
        name: "My very first bucket",
        category: "Life goal",
        description: '',
    }
    constructor(private _formBuilder: FormBuilder) {

    }

    ngOnInit() {
        this.basicInfoGroup = this._formBuilder.group({
            bucketName: ['', Validators.required],
            firstCtrl: ['', Validators.required],
        });
        this.individualFormGroup = this._formBuilder.group({
            picture: [''],
        });
        this.firstFormGroup = this._formBuilder.group({
            firstCtrl: ['', Validators.required]
        });
        this.secondFormGroup = this._formBuilder.group({
            secondCtrl: ['', Validators.required]
        });
    }
}
import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-image-control',
  templateUrl: './image-control.component.html',
  styleUrls: ['./image-control.component.css']
})
export class ImageControlComponent implements OnInit {

  dragover: boolean;
  dropLabel: string;

  loading: boolean;

  constructor() {
    this.dropLabel = 'Drop your image here';
    this.loading = false;
  }

  ngOnInit() {
  }

 @HostListener('body:dragover', ['$event'])
 blockDragOver(event: DragEvent) {
  event.preventDefault();
  event.dataTransfer.dropEffect = 'none';
  return false;
}

@HostListener('body:dragenter', ['$event'])
blockDragEnter(event: DragEvent) {
 event.preventDefault();
 event.dataTransfer.dropEffect = 'none';
 return false;
}


  // At the drag drop area
  // (drop)="onDropFile($event)"
  onDropFile(event: DragEvent) {
    event.preventDefault();
    this.dragover = false;
    this.uploadFile(event.dataTransfer.files);
  }

  // At the drag drop area
  // (dragover)="onDragOverFile($event)"
  onDragOverFile(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.checkEvent(event);
  }

  checkEvent(event: DragEvent) {
    if (event.dataTransfer.types.includes('Files')) {
      event.dataTransfer.dropEffect = 'copy';
      this.dragover = true;
    } else {
      event.dataTransfer.dropEffect = 'none';
      this.dragover = false;
    }
  }

  onDragStart(event) {
    this.dropLabel = 'Drop your image here';
  }

  onDragEnter(event) {
    this.checkEvent(event);
  }
  onDragLeave(event) {
    this.dragover = false;
  }

  // At the file input element
  // (change)="selectFile($event)"
  selectFile(event) {
    this.uploadFile(event.target.files);
  }

  uploadFile(files: FileList) {
    console.log('Upload:', files);

    this.loading = true;
  }
}

import { Injectable } from '@angular/core';
import { ImageService } from '../services/image.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { HttpClient } from '@angular/common/http';
import { map, flatMap, filter, finalize } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Upload, Image } from '../services';

import { DBImage, DBPrepareImage } from './model';

import { v4 as uuid } from 'uuid';
import { Observable, Subject } from 'rxjs';



@Injectable()
export class FirebaseImageService extends ImageService {

    private imageCollection: AngularFirestoreCollection<DBImage>;
    private processorCollection: AngularFirestoreCollection<DBPrepareImage>;

    constructor(
        private fbStorage: AngularFireStorage,
        private fbFirestore: AngularFirestore,
        private httpClient: HttpClient,
    ) {
        super();

        this.imageCollection = fbFirestore.collection('images');
        this.processorCollection = fbFirestore.collection('processors');
    }

    prepareImage(type: string, upload: Upload): Image {
        console.log('prepareImage ' + type);
        const imageId = uuid();
        const path = `${type}/${imageId}`;
        const img: DBImage = {
            path,
            bucket: '',
            creator: '',
            ready: false,
        };

        const imageDoc: AngularFirestoreDocument<DBImage> = this.imageCollection.doc(imageId);
        imageDoc.set(img)
        .then(d => console.log('image doc saved', d))
        .catch(e => console.error('image doc not saved', e));

        const t: DBPrepareImage = {
            type: 'prepareImage',
            source: upload.id,
            destination: imageId,
        };
        this.processorCollection.add(t)

        .then(d => console.log('pocessor doc saved', d))
        .catch(e => console.error('processor doc not saved', e));

        const sData: Subject<Blob> = Subject.create();
        const sReady: Subject<boolean> = Subject.create();
        const result: Image = {
            id: imageId,
            data: sData,
            path,
            ready: sReady,
        };
        imageDoc.snapshotChanges().pipe(
            filter(i => i.payload.data().ready)
        ).subscribe(x => {
            console.log('image ready, downloading');
            this.downloadBlob(upload.path).subscribe(sData);
        });

        return result;
    }

    downloadBlob(path: string): Observable<Blob> {
        const storageRef = this.fbStorage.ref(path);
        return storageRef.getDownloadURL().pipe(flatMap(url => this.httpClient.get(url, { responseType: 'blob' })));
    }

    loadImage(imageId: string): Image {
        const imageIdParts = imageId.split('/');
        const type = imageIdParts[0];
        const id = imageIdParts[1];

        const path = `${type}/${id}`;
        const storageRef = this.fbStorage.ref(path);

        const img: Image = {
            id: imageId,
            path,
            data: storageRef.getDownloadURL().pipe(flatMap(url => this.httpClient.get(url, { responseType: 'blob' }))),
            ready: Subject.create(),
        };

        return img;
    }

    uploadImage(imageId: string, data: File): Image {
        const path = `/images/uploaded/${data.name}`;

        const img = new Image();
        /*
        img.onload = () => {
            URL.revokeObjectURL(img.src);
            console.log('img loaded');
            console.log('dim: ', img.naturalWidth, 'x', img.naturalHeight);
        };
        img.src = URL.createObjectURL(data);

        this.imageCollection.add({
            uploadPath: path,
            ready: false,
        }).then(ref => {
            console.log('image inserted in db: ', ref);

            const task = this.fbStorage.upload(path, data);

        }).catch(error => console.error('FAIL', error));
*/

        throw new Error('Method not implemented.');
    }
}

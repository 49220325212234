import { NgModule } from '@angular/core';
import { UploadService } from '../services/upload.service';
import { FirebaseUploadService } from './firebase.upload.service';
import { AuthService } from '../services/auth.service';
import { FirebaseAuthService } from './firebase.auth.service';
import { ProfileService } from '../services/profile.service';
import { FirebaseProfileService } from './firebase.profile.service';
import { FirebaseImageService } from './firebase.image.service';
import { ImageService } from '../services/image.service';
import { BucketService } from '../services/bucket.service';
import { FirebaseBucketService } from './firebase.bucket.service';

@NgModule({
  imports: [],
  exports: [],
  providers: [
      { provide: AuthService, useClass: FirebaseAuthService },
      { provide: ProfileService, useClass: FirebaseProfileService },
      { provide: UploadService, useClass: FirebaseUploadService },
      { provide: ImageService, useClass: FirebaseImageService },
      { provide: BucketService, useClass: FirebaseBucketService },
  ],
})
export class FirebaseServiceModule { }

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppAngularFireModule } from './app-angularfire.module';

import { AppComponent } from './app.component';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { BucketComponent } from './bucketlist/bucket.component';
import { BucketWizardComponent } from './bucketlist/bucketwizard.component';


import { BucketlistComponent } from './bucketlist/bucketlist.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { UserComponent } from './user/user.component';

import { ProfileComponent } from './profile/profile.component';


import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTwitter, faGoogle, faFacebook } from '@fortawesome/free-brands-svg-icons';

import { UserModule } from './user/user.module';
import { SettingsRoutingModule } from './user/settings-routing.module';
import { UploadService } from './uploads/upload.service';
import { UploadFormComponent } from './uploads/upload-form/upload-form.component';
import { FirebaseServiceModule } from './services-firebase/firebase.service.module';

import { HttpClientModule } from '@angular/common/http';
import { ImageControlComponent } from './image-control/image-control.component';
import { AdminModule } from './admin/admin.module';
import { ExperienceMarketplaceModule } from './experience-marketplace/experience-marketplace.module';
import { GuardComponent } from './guard.component';


@NgModule({
  declarations: [
    AppComponent,
    BucketComponent,
    BucketlistComponent,
    BucketWizardComponent,
    PageNotFoundComponent,
    GuardComponent,

    ProfileComponent,
  ],
  imports: [
    BrowserModule,

    HttpClientModule,

    UserModule,
    AdminModule,
    AppRoutingModule,

    AppAngularFireModule,

    FormsModule,
    ReactiveFormsModule,


    MaterialModule,
    BrowserAnimationsModule,

    FontAwesomeModule,

    FirebaseServiceModule,

    ExperienceMarketplaceModule,

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    library.add(faTwitter, faGoogle, faFacebook);
  }
}

import { Injectable } from '@angular/core';
import { BucketService, Bucket } from '../services';
import { AngularFireStorage } from '@angular/fire/storage';
import { HttpClient } from '@angular/common/http';
import { map, flatMap, filter, finalize } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';

import { DBBucket } from './model';

import { v4 as uuid } from 'uuid';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class FirebaseBucketService extends BucketService {

    private bucketsCollection: AngularFirestoreCollection<DBBucket>;

    constructor(
        private fbStorage: AngularFireStorage,
        private fbFirestore: AngularFirestore,
        private httpClient: HttpClient,
    ) {
        super();

        this.bucketsCollection = fbFirestore.collection('buckets');
       
    }

    getBuckets(): Observable<Bucket[]> {
        return this.bucketsCollection.snapshotChanges().pipe(map(changes => changes.map(
            change => {
                const data = change.payload.doc.data() as DBBucket;
                const id = change.payload.doc.id;
                return <Bucket>({
                    id,
                    name: data.name,
                    description: data.description,
                    category: data.category,
                });
            }
        )));
    }

    saveBucket(bucket: Bucket) {

    }
    deleteBucket(bucket: Bucket) {

    }

}

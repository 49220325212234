import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Upload, Image } from './model';




@Injectable()
export abstract class ImageService {

    abstract loadImage(imageId: string): Image;

    abstract uploadImage(imageId: string, data: File): Image;

    abstract prepareImage(type: string, upload: Upload): Image;
}

import { Injectable } from '@angular/core';

import { Upload } from './model';

@Injectable()
export abstract class UploadService {

    public abstract pushUpload(data: Blob): Upload;

}

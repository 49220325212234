import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-social-avatar',
  templateUrl: './settings-social-avatar.component.html',
  styleUrls: ['./settings-social-avatar.component.css']
})
export class SettingsSocialAvatarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

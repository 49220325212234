import { Component, OnInit } from '@angular/core';
import { Upload } from '../../services';
import { UploadService } from '../../services';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.css']
})
export class UploadFormComponent implements OnInit {

  selectedFile: File;
  currentUpload: Upload;

  constructor(
    private uploadService: UploadService,
    private imageService: ImageService,
  ) { }

  ngOnInit() {
  }

  uploadFile() {
    this.currentUpload = this.uploadService.pushUpload(this.selectedFile);
    const image = this.imageService.prepareImage('avatar', this.currentUpload);
    console.log(image);
  }

  detectFiles(event) {
    if (event.target.files.length === 0) {
      this.selectedFile = null;
    } else {
      this.selectedFile = event.target.files[0];
    }
  }

}

import { Observable } from 'rxjs';

export class Resource {
    public id: string;
    public ready: Observable<boolean>;
    public path: string;
}

export class BlobResource extends Resource {
    public data: Observable<Blob>;
}

export class Upload extends BlobResource {
    public progress: Observable<number>;
}

export class Image extends BlobResource {

}

export class Bucket {
    public id: string;
    public name: string;
    public description: string;
    public category: string;
}

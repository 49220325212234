import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Image, User } from '../services';
import { AuthService } from '../services/auth.service';
import { ProfileService } from '../services/profile.service';
import { FirebaseFirestore } from '@angular/fire';
import { AngularFirestoreCollection, AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { v4 as uuid } from 'uuid';
import { map, flatMap } from 'rxjs/operators';

export interface Profile {
    userid: string;
}

class DbProfile {
    userid: string;
    portraitid: string;
}

@Injectable()
export class FirebaseProfileService extends ProfileService {

    private currentUser: Observable<User>;
    private currentProfile: Observable<DbProfile>;
    private dbProfiles: AngularFirestoreCollection<DbProfile>;

    constructor(
        private authService: AuthService,
        private fbFirestore: AngularFirestore,
    ) {
        super();
        this.currentUser = authService.getCurrentUser();
        this.dbProfiles = fbFirestore.collection('profiles');

        this.currentProfile = this.currentUser.pipe(flatMap(u => {
            console.log('resolving curentProfile', u);
            const document: AngularFirestoreDocument<DbProfile> = this.dbProfiles.doc(u.uid);
            console.log('resolving curentProfile', document);
            return document.valueChanges();
        }));

        this.userId = this.currentProfile.pipe(map(p => {
            console.log('map userId', p);
            return p ? p.userid : null;
        }));

    }

    updateEmail(email: string) {

    }
    checkUserId(userId: string) {

    }
    updateUserId(userId: string) {

    }

    createUserId(): string {
        return uuid();
    }

    initializeProfile(user: User) {
        if (user) {
            const doc = this.dbProfiles.doc(user.uid);
            const obj = {
                userid: this.createUserId(),
            };
            console.log('setting', obj, '@', doc);
            doc.set(obj).then(done => {
                console.log('initializeProfile done.', done);
            }).catch(error => {
                console.log('initializeProfile failed', error);
            });
        } else {
            console.log('initializeProfile failed - no user given');
        }
    }
    deleteProfile(user: User) {
        if (user) {
            const doc = this.dbProfiles.doc(user.uid);
           doc.delete().then(done => {
                console.log('deleteProfile done.', done);
            }).catch(error => {
                console.log('deleteProfile failed', error);
            });
        } else {
            console.log('deleteProfile failed - no user given');
        }
    }
}

import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  guard = true;

  title = 'application';

  guardDone = 0;

  onKeyPress(event) {
    // hacky guard

    if (this.guardDone === 0 && event.key === 'L') {
      this.guardDone = 1;
    }
    if (this.guardDone === 1 && event.key === 'T') {
      this.guard = false;
    }
  }
}
